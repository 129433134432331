<template>
  <div>
    <Component
      v-for="(component, index) in components"
      v-bind:key="index"
      v-bind:is="component.name"
      v-bind:props="component.props"
    />
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>

<script>
import { ArrowUpIcon } from "vue-feather-icons";
export default {
  metaInfo: {
    title: "Solicitudes",
  },
  components: {
    ArrowUpIcon,
  },
  computed: {
    components() {
      return this.$store.getters["system/pages"].find(
        (page) => page.name == "Requests"
      ).children;
    },
  },
};
</script>